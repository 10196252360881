import React, { useState, useEffect } from 'react';
import { analytics as firebaseAnalytics } from './components/firebase/firebase';
import { logEvent } from 'firebase/analytics';
import { track } from '@vercel/analytics';

const crypticElements = [
 '⌬', '◈', '⌭', '⎔', '◑', '◐', '⌾', '⍜', '∰', '⎈',
 '0x1f4b9', '0x3a7d', '0xe29b', 
 '[][>', '</>>', '>>[]',
 '∆∆', '◊◊', '##', '||',
 '//*', '*\\', '\\*', '*/',
];

// Common analytics tracking function to handle both Firebase and Vercel
const trackEvent = (eventName, eventParams = {}) => {
  // Firebase Analytics
  logEvent(firebaseAnalytics, eventName, eventParams);
  
  // Vercel Analytics
  track(eventName, eventParams);
  
  // For debugging in development
  if (process.env.NODE_ENV === 'development') {
    console.log(`Event tracked: ${eventName}`, eventParams);
  }
};

const LandingPage = () => {
 const [isVisible, setIsVisible] = useState(false);
 const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
 const [time, setTime] = useState(0);
 const [isMobile, setIsMobile] = useState(false);
 const [hasInteracted, setHasInteracted] = useState(false);
 const [visitDuration, setVisitDuration] = useState(0);

 // Log page view when LandingPage mounts
 useEffect(() => {
  // Track page view
  trackEvent('page_view', { 
    page: 'landing_page',
    referrer: document.referrer,
    screen_size: `${window.innerWidth}x${window.innerHeight}`,
    is_mobile: window.innerWidth < 768
  });
  
  // Record session start time
  const sessionStartTime = new Date().getTime();
  
  // Track session duration
  const durationInterval = setInterval(() => {
    const currentDuration = Math.floor((new Date().getTime() - sessionStartTime) / 1000);
    setVisitDuration(currentDuration);
    
    // Track engagement milestones (30s, 60s, 2min, 5min)
    if (currentDuration === 30 || currentDuration === 60 || 
        currentDuration === 120 || currentDuration === 300) {
      trackEvent('engagement_milestone', { 
        duration_seconds: currentDuration,
        has_interacted: hasInteracted
      });
    }
  }, 1000);
  
  // Track when user leaves the page
  const handleBeforeUnload = () => {
    trackEvent('page_exit', { 
      duration_seconds: Math.floor((new Date().getTime() - sessionStartTime) / 1000),
      has_interacted: hasInteracted
    });
  };
  
  window.addEventListener('beforeunload', handleBeforeUnload);
  
  return () => {
    clearInterval(durationInterval);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [hasInteracted]);

useEffect(() => {
  setIsVisible(true);
  const interval = setInterval(() => {
    setTime(t => t + 1);
  }, 1000);

  const checkMobile = () => {
    const isMobileView = window.innerWidth < 768;
    if (isMobileView !== isMobile) {
      setIsMobile(isMobileView);
      // Track screen size changes
      trackEvent('screen_size_change', { 
        width: window.innerWidth,
        height: window.innerHeight,
        is_mobile: isMobileView
      });
    }
  };
  
  checkMobile();
  window.addEventListener('resize', checkMobile);

  return () => {
    clearInterval(interval);
    window.removeEventListener('resize', checkMobile);
  };
}, [isMobile]);

const handleMouseMove = (e) => {
  if (isMobile) return;
  
  const x = (e.clientX / window.innerWidth) * 100;
  const y = (e.clientY / window.innerHeight) * 100;
  setMousePosition({ x, y });
  
  if (!hasInteracted) {
    setHasInteracted(true);
    trackEvent('first_interaction', { type: 'mouse_move' });
  }
};

const handleClick = () => {
  trackEvent('cta_click', { 
    location: 'landing_page',
    element: 'main_title',
    visit_duration: visitDuration
  });
  
  if (!hasInteracted) {
    setHasInteracted(true);
  }
};


 return (
   <div 
     className="min-h-screen bg-black text-white flex flex-col items-center justify-center relative overflow-hidden"
     onMouseMove={handleMouseMove}
   >
     <div 
       className="absolute inset-0 bg-gradient-to-br from-transparent to-gray-900 opacity-40 transition-transform duration-700 backdrop-blur-sm"
       style={{
         transform: isMobile 
           ? `translate(${Math.sin(time * 0.05) * 3}px, ${Math.cos(time * 0.05) * 3}px)` 
           : `translate(${mousePosition.x / 50}px, ${mousePosition.y / 50}px)`
       }}
     />
     
     <div className="absolute inset-0 overflow-hidden">
       {[...Array(isMobile ? 10 : 20)].map((_, i) => {
         const randomElement = crypticElements[Math.floor(Math.random() * crypticElements.length)];
         const speed = 0.05 + Math.random() * 0.2;
         const delay = Math.random() * 10;
         const size = isMobile ? (6 + Math.random() * 10) : (8 + Math.random() * 15);
         
         return (
           <div
             key={i}
             className="absolute text-gray-700 font-mono transition-all duration-1000"
             style={{
               top: `${(time * speed + delay) % 100}%`,
               left: `${Math.random() * 100}%`,
               transform: `rotate(${time * speed * 10}deg)`,
               fontSize: `${size}px`,
               opacity: 0.03 + Math.random() * 0.08,
             }}
           >
             {randomElement}
           </div>
         );
       })}
     </div>

     <div className="absolute inset-0 grid grid-cols-4 md:grid-cols-8 grid-rows-4 md:grid-rows-8 opacity-10">
       {[...Array(isMobile ? 16 : 64)].map((_, i) => (
         <div key={i} className="border border-gray-800" />
       ))}
     </div>

     <div className="absolute inset-0 pointer-events-none">
       {[...Array(isMobile ? 3 : 8)].map((_, i) => (
         <div
           key={i}
           className="absolute top-0 text-xs font-mono text-gray-700"
           style={{
             left: `${i * (isMobile ? 20 : 10)}%`,
             transform: `translateY(${(time * 50 + i * 100) % 1000}px)`,
             opacity: 0.05,
           }}
         >
           {Math.random().toString(2).substr(2, 8)}
         </div>
       ))}
     </div>

     <div 
       className={`transform transition-all duration-1000 z-10 px-4 ${
         isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
       }`}
     >
       <div className="relative">
         <div className="absolute -inset-4 md:-inset-8 border border-gray-800 opacity-20 rotate-1" />
         <div className="absolute -inset-4 md:-inset-8 border border-gray-800 opacity-10 -rotate-1" />
         
         {[[-1, -1], [1, -1], [-1, 1], [1, 1]].map(([x, y], i) => (
           <div
             key={i}
             className="absolute w-6 md:w-8 h-6 md:h-8 border-gray-700 opacity-30"
             style={{
               top: y < 0 ? '-0.75rem' : 'auto',
               bottom: y > 0 ? '-0.75rem' : 'auto',
               left: x < 0 ? '-0.75rem' : 'auto',
               right: x > 0 ? '-0.75rem' : 'auto',
               borderTop: y < 0 ? '1px solid' : 'none',
               borderBottom: y > 0 ? '1px solid' : 'none',
               borderLeft: x < 0 ? '1px solid' : 'none',
               borderRight: x > 0 ? '1px solid' : 'none',
             }}
           />
         ))}

         <h1 className="text-lg sm:text-2xl md:text-4xl font-bold text-center tracking-wider relative cursor-pointer"
         onClick={handleClick}
         >
           <span className="block text-sm sm:text-base md:text-lg mb-2 md:mb-4 opacity-70">
             Powered by
           </span>
           <span className="bg-gradient-to-r from-white to-gray-500 text-transparent bg-clip-text hover:to-white transition-all duration-500">
             cryptonames.world
           </span>
         </h1>

         <div className="absolute inset-0 flex items-center justify-center opacity-30 pointer-events-none">
           <span 
             className="text-lg sm:text-2xl md:text-4xl font-bold tracking-wider text-white mix-blend-difference"
             style={{
               transform: `translate(${Math.sin(time) * (isMobile ? 0.5 : 1)}px, ${Math.cos(time) * (isMobile ? 0.5 : 1)}px)`
             }}
           >
             cryptonames.world
           </span>
         </div>
       </div>
     </div>
   </div>
 );
};

export default LandingPage;