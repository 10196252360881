import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZMZUkSey7Epgpy-GV5j3Kik1ZVccXScc",
  authDomain: "crypto-world-cfd21.firebaseapp.com",
  projectId: "crypto-world-cfd21",
  storageBucket: "crypto-world-cfd21.firebasestorage.app",
  messagingSenderId: "164907671198",
  appId: "1:164907671198:web:47489baf481880ab3f658f",
  measurementId: "G-M01NKSHD4J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, analytics };